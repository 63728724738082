import React, { Component } from "react"
import SEO from "../../components/seo"
import { graphql, Link, StaticQuery } from "gatsby"
import "../../styles/base.scss"
import "../../styles/podcast.scss"

import badgeApple from "../../images/podcast/apple.svg"
import badgeSpotify from "../../images/podcast/spotify.svg"
import PodcastHeader from "../../components/PodcastHeader"

class PodcastIndex extends Component {
  constructor(data) {
    super()
  }

  render() {
    console.log(this.props.site.allMdx.edges)
    return (
      <div>
        <SEO
          title={`TRANS BOOKS Podcast`}
          ogImagePath={`/assets/img/podcast.png`}
          lang={`ja`}
          description={`メディアなんでも書店こと TRANS BOOKS の運営委員とさまざまなゲストがお届けするポッドキャスト。Apple Podcasts / Spotify からもお楽しみいただけます。`}
        />
        <PodcastHeader />

        <div className={`l-wrap mt0`}>
          <div className={`podcastMain`}>
            <div className={`podcastHead`}>
              <p className={`podcastHead__description`}>
                メディアなんでも書店こと TRANS BOOKS
                の運営委員とさまざまなゲストがお届けするポッドキャスト。Apple
                Podcasts / Spotify などからもお楽しみいただけます。
              </p>

              <div className={`podcastLinks`}>
                <ul>
                  <li>
                    <a
                      href="https://podcasts.apple.com/jp/podcast/trans-books-podcast/id1525113006"
                      target={`_blank`}
                    >
                      <img src={badgeApple} alt="listen on Apple Podcast" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://open.spotify.com/show/2yuNqxhcEBbH8RSeWpQKte"
                      target={`_blank`}
                    >
                      <img src={badgeSpotify} alt="listen on Spotify Podcast" />
                    </a>
                  </li>
                </ul>

                <p>
                  <a href="/podcast-rss-feed.xml" target={`_blank`}>
                    RSS feed はこちら
                  </a>
                </p>
              </div>
            </div>
            <ul className={`podcastEpisode`}>
              {this.props.site.allMdx.edges.map(({ node }) => (
                <li key={node.id}>
                  <h2>
                    <Link to={node.frontmatter.slug}>
                      {node.frontmatter.title}
                    </Link>
                  </h2>
                  <p className={`podcastEpisode__season`}>
                    Season {node.frontmatter.season} / Episode{" "}
                    {node.frontmatter.episodeNumber} /
                    {node.frontmatter.duration},{" "}
                    {Math.floor((node.frontmatter.size / 1024 / 1024) * 100) /
                      100}
                    MB
                  </p>
                  <audio
                    src={node.frontmatter.url}
                    preload="none"
                    controls
                  ></audio>
                </li>
              ))}
            </ul>
            <p>
              <small>ジングル楽曲: <a href="https://soundcloud.com/garageyokotaband" target={`_blank`} style={{color:`black`}}>GarageYokotaBand</a></small>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(
            filter: {
              frontmatter: {
                type: { eq: "podcast-episode" }
                status: { eq: "published" }
              }
            }
            sort: { fields: frontmatter___slug, order: DESC }
          ) {
            edges {
              node {
                id
                frontmatter {
                  author
                  categories
                  url
                  type
                  title
                  subtitle
                  status
                  slug
                  size
                  season
                  publicationDate
                  guid
                  explicit
                  excerpt
                  episodeType
                  episodeNumber
                  duration
                }
              }
            }
          }
        }
      `}
      render={data => <PodcastIndex site={data} />}
    />
  )
}
